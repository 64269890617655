import '@/styles/globals.sass';

import Lenis from '@studio-freight/lenis';
// @ts-ignore
import PageTransitions from '@/components/PageTransitions';
import {useRouter} from 'next/router';
import {useEffect, useRef, useState} from 'react';
import gsap from 'gsap/dist/gsap';
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger';
import {DefaultSeo} from 'next-seo';
import SEO from '/next-seo.config';
import Matomo from '/utils/Matomo';

gsap.registerPlugin(ScrollTrigger,);

//region Lenis - Smooth scroll
if (typeof window !== 'undefined') {
    const lenis = new Lenis({
        lerp: .15, smoothWheel: true,
    });
    lenis.on('scroll', () => ScrollTrigger.update());

    const raf = (time) => {
        lenis.raf(time);
        ScrollTrigger.update();
        requestAnimationFrame(raf);
    };

    requestAnimationFrame(raf);
}
//endregion

export default function App({
                                Component, pageProps,
                            }) {
    // usePageTransitionFix();

    const firstLoad = useRef(true);

    const router = useRouter();

    const [routingPageOffset, setRoutingPageOffset] = useState(0);
    useEffect(() => {
        const pageChange = () => {
            setRoutingPageOffset(window.scrollY);
        };
        router.events.on('routeChangeStart', pageChange);
    }, [router.events]);

    useEffect(() => {
        firstLoad.current = false;
    });

    return <>
        <Matomo/>
        <DefaultSeo {...SEO} />
        <PageTransitions
            route={router.asPath}
            routingPageOffset={routingPageOffset}
        >
            <Component {...pageProps} firstLoad={firstLoad.current}/>
        </PageTransitions>
    </>;
}
