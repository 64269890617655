import styled                           from 'styled-components'
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import gsap, {Power0, Power1}           from 'gsap'

const MainComponent = styled.div`
	&.page-enter-active {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;

		will-change: transform;
		overflow: hidden;
	}

	&.page-exit-active {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		overflow: hidden;

		will-change: transform;

		.page-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.page-wrapper {
			position: absolute;
			top: -${props => props.routingPageOffset}px;
			left: 0;
			width: 100%;
		}
	}

	&.page-enter-done {
		transform: none !important;
	}
`

const PageTransitions = ({
	                         children,
	                         route,
	                         routingPageOffset,
                         }) => {

	//	const timeScale = .05
	//	const timeScale = .001
	const timeScale = 1

	const scaleDown     = .6
	const scaleDuration = .3
	const scaleEase     = Power1.easeInOut

	const moveDuration = .4
	const moveEase     = Power1.easeInOut

	const enterTransition = (elt) => {
		gsap.set(elt, {
			height: '100vh',
			scale:  scaleDown,
		})

		gsap.timeline({})
		    .timeScale(timeScale)
		    .fromTo(elt, {
			            xPercent: 100,
		            },
		            {
			            delay: scaleDuration,

			            xPercent: 0,
			            duration: moveDuration,
			            ease:     moveEase,
		            },
		    )
		    .to(elt, {
			    scale:    1,
			    duration: scaleDuration,
			    ease:     scaleEase,
		    }, '>-20%')
		    .set(elt, {
			    height: 'auto',
		    })
	}

	const exitTransition = (elt) => {
		gsap.timeline({})
		    .timeScale(timeScale)
		    .set(elt, {
			    height: '100vh',
		    })
		    .to(elt, {
			    scale:    scaleDown,
			    duration: scaleDuration,
			    ease:     scaleEase,
		    })
		    .to(elt, {
			    xPercent: -100,
			    duration: moveDuration,
			    ease:     moveEase,
		    }, '>2%')
		    .set(elt, {
			    height: 'auto',
		    })
	}

	return (
		<>
			<TransitionGroup
				className={'transition-container'}
			>
				<CSSTransition
					key={route}
					classNames={'page'}
					timeout={(
						         moveDuration + scaleDuration * 2
					         ) * 1000 / timeScale}
					//					timeout={100_000}

					onEnter={enterTransition}
					onExit={exitTransition}
				>
					<MainComponent routingPageOffset={routingPageOffset}>
						<div className='page-container'>
							<div className='page-wrapper'>
								{children}
							</div>
						</div>
					</MainComponent>
				</CSSTransition>
			</TransitionGroup>
		</>
	)
}

export default PageTransitions
