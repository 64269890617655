const config = {
    titleTemplate: '%s - Next Step',
    defaultTitle: 'Next Step',
    openGraph: {
        type: 'website',
        locale: 'fr_FR',
        url: 'https://www.next-step.fr',
        siteName: 'Next Step',
    },
    dangerouslySetAllPagesToNoIndex: false,
    dangerouslySetAllPagesToNoFollow: false,
};

export default config;
